.smi-release {
    .row-has-error {
        border: 1px solid #d63031;
        padding: 1px !important;
        line-height: 20px;
        width: 20px;
    }

    .status-error {
        border: 1px solid #d63031;
        line-height: 24px;
        height: 25px;
        .status-value {
            margin-left: 5px;
        }
    }

    .ant-picker {
        border-radius: 8px !important;
        font-size: 12px !important;
        .ant-picker-input {
            &> input {
                font-size: 12px !important;
                padding: 2px 0px;
            }
        }
    }

    .grid-actions-top {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 0px 5px 0px 5px;
        font-size: 16px;
        &> div {
            margin-left: 10px;
            .ant-icon {
                font-size: 16px;
            }
        }
    }

    .ant-input {
        border-radius: 8px;
        font-size: 12px !important;
    }

    .ag-cell {
        border-right: 1px solid #d9dcde !important;
    }

    .ag-row-selected {
        background-color: #dff9fb !important;
    }

    .ag-cell-value {
        .ant-picker {
            margin: 3px 5px !important;
        }
    }
}

.parts-container {
    .ag-theme-balham .ag-row-odd {
        background-color: #f0ebeb;
      }
}

.inside-lt-row {
    background-color: #DCF0F7 !important;
}

.fc-popover-content {
    line-height: 15px;
}

.fc-container{
    .ant-input {
        border-radius: 8px;
        font-size: 12px !important;
    }
}