/* Components */
@import "./components/grid.scss";
@import "./components/button.scss";
@import "./components/card.scss";
@import "./components/dashboard.scss";
@import "./components/export_history.scss";
@import "./components/login.scss";
@import "./components/modal.scss";
@import "./components/sider.scss";
@import "./components/steps.scss";
@import "./components/support_docs.scss";
@import "./components/acp/candidate-item.scss";
@import "./components/cpt/cpt.scss";
@import "./components/ssp/testlogs.scss";
@import "./components/ssp/ssp.scss"; 
@import "./components/ssp/questionnaire.scss";
@import "./components/column-config.scss";
@import "./components/ssp/edi.scss";
@import "./components/ssp/guidelines.scss";
@import "./components/ssp/testing-form.scss";
@import "./components/search.scss";
@import "./components/pc/rtf.scss";
@import "./components/pc/asn.scss";
@import "./components/pc/inv.scss";
@import "./components/pc/smi.scss";
@import "./components/pc/site-executor.scss";
@import "./components/pc/nbs.scss";
@import "./components/pc/reschedules.scss";
@import "./components/pc/vci.scss";
@import "./components/cds/report.scss";
@import "./components/cds/dynamic-report.scss";
@import "./components/pc/helpdesk.scss";
@import "./components/pc/datepicker.scss";

// Color variables
$white: #ffffff; // Appears 15 times
$tab-content-border: #e8e8e8; // Appears 2 times
$carousel-color: #34495e; // Appears 2 times

// Width variables
$width-full: 100%; // Appears 7 times

// Height variables
$height-full: 100%; // Appears 5 times
$height43: 43px; // Appears 2 times
$height20: 20px; // Appears 3 times

.ant-layout-sider {
	background: $white;
	&:-webkit-scrollbar {
    width: 5px;
  }
  &:-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
  }
	.trigger {
		float: right;
		font-size: 12px;
		margin-right: 6%;
		margin-top: 4%;
	}
	.ant-layout-sider-children {
		.logo {
			width: 38%;
			span {
				margin-left: 23%;
				margin-top: 1%;
				width: $width-full;
			}
		}
		.profile-space {
			border-radius: 4px;
			margin: 10px;
			overflow: hidden;
			padding: 5px;
			.ant-avatar {
				background: $white;
				cursor: pointer;
				display: inline-block;
				line-height: unset;
				vertical-align: sub;
			}
			.gx-avatar-name {
				display: inline-block;
				font-size: 14px;
				margin: 8px 5px;
				overflow: hidden;
				padding: 0;
				position: relative;
				text-align: center;
				text-decoration: none;
				text-overflow: ellipsis;
				vertical-align: top;
				white-space: nowrap;
				width: auto;
			}
			.caret {
				color: $white;
				float: right;
				margin-right: 4%;
				margin-top: 8%;
			}
		}
	}
}
.ht-100 {
	height: $height-full;
}
.hz-padding-10 {
	padding: 0 10px;
}
.hz-padding-100 {
	padding: 0 100px;
	padding: 0 20px;
}
.hz-padding-25 {
	padding: 0 20px;
	padding: 0 25px;
}
.mt-10px {
	margin-top: 10px;
}
.mt-20px {
	margin-top: 20px;
}
.mb-25px {
	margin-bottom: 25px;
}
.ta-center {
	text-align: center;
}
.black {
	color: rgba(0, 0, 0, 0.25);
}
.logo-container {
	height: 43px;
	margin: 2px 25px;
	width: 78px;
}
.form-element {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	height: $height-full;
	padding: 5%;
	.ant-input-affix-wrapper {
		.ant-input {
			min-height: auto;
		}
	}
	.logo {
		padding-bottom: 50px;
		text-align: center;
		width: $width-full;
	}
	.ant-avatar-lg {
		height: 50% !important;
		width: 35% !important;
	}
	.login-form-button {
		display: block;
		text-align: center;
		width: $width-full;
	}
	.login-form-forgot {
		float: right;
	}
	.ant-form-item {
		margin-bottom: 5px;
	}
}
.ant-input {
	&:focus {
		-webkit-box-shadow: 0 0 0 2px rgba(252,98,98,0.2);
		box-shadow: 0 0 0 2px 0 0 0 2px rgba(252,98,98,0.2);
	}
}
.login-header {
	box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 4px 4px !important;
	height: 47px !important;
	line-height: 0 !important;
}
.slider {
	border-radius: 16px;
}
.slider-frame {
	height: $height-full !important;
}
.slider-control-centerleft {
	display: none;
}
.slider-control-centerright {
	display: none;
}
.slider-col {
	border-radius: 8px;
	height: $height-full;
}
.form-col {
	position: absolute;
	position: static;
	right: 0%;
	right: 2%;
	width: $width-full;
}
.sliderImg {
	height: auto;
	opacity: 0.9;
	width: $width-full;
}
@media (max-width :576px) {
  .disp-none { 
    display: none;
  }
}
.ant-layout-header {
	height: $height43;
	line-height: 10px;
	padding: 0%;
	.ant-row {
		width: $width-full;
	}
	.title-caption {
		color: #f2f2f2;
		font-size: 18px;
		font-weight: 500;
		margin-top: 1%;
		display: inline;
	}
	.nav {
		float: right;
		font-size: 25px;
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
	}
	.nav>li {
		display: inline-block;
		margin: 0px;
		padding: 0px;
		position: relative;
	}
	.nav>li>a {
		color: $white;
		display: block;
		margin: 0px;
		padding: 10px 20px;
		position: relative;
	}
	.ant-menu-horizontal {
		background: inherit;
		border-bottom: none;
		color: $white;
		li {
			float: right;
			height: inherit;
			margin-right: 10px;
		}
		.ant-menu-item {
			.anticon {
				font-size: 20px;
			}
		}
		float: left;
	}
	.ant-col-6 {
		position: absolute;
		right: 0%;
		margin-top: 1%;
	}
	.logo {
		cursor: pointer;
		vertical-align: middle;
		line-height: 43px;
    	margin-left: 15%;
	}
	.logo-avator {
		width: 69px;
		height: 41px;
		margin-left: 4px;
		padding: 1px 1px 0px 0px;
	}
	.trigger {
		font-size: 22px;
		color: #FFFFFF;
		vertical-align: middle;
		line-height: 43px;
		margin-left: 10%;
		margin-top: 5px;
	}
	.trigger-red {
		font-size: 22px;
		color: #ee3a43;
		vertical-align: middle;
		line-height: 43px;
		margin-left: 10%;
	}
	.toggle-content {
		vertical-align: middle;
		line-height: 43px;
	}
	.flex-content {
		width: 100%;
	}
	.title-content {
		width: 500px;
		vertical-align: middle;
		line-height: 43px;
	}
	.user-content {
		vertical-align: middle;
		margin-right: 2%;
		float: right;
		cursor: pointer;
	}
	.user-avatar {
		background-color: #f0932b;
	}
	.user-name {
		color: #ffffff;
	}
	.header-menu-content {
		vertical-align: middle;
		line-height: 43px;
	}
}

.ant-layout-content {
	overflow-y: hidden;
	padding: 5px;
	.anticon-caret-up {
		position: absolute;
		right: 2%;
		top: 2%;
	}
	.ant-tabs-bar {
		margin-bottom: 0px;
	}
	.ant-tabs {
		.ant-tabs-top-content {
			padding: 10px;
		}
		.ant-tabs-bottom-content {
			padding: 10px;
		}
	}
	.page-titles {
		background-color: $white;
		display: inline-flex;
		margin-bottom: 1%;
		margin-left: -2%;
		width: 104%;
		.anticon {
			margin-top: 0.25%;
			position: absolute;
			right: 28px;
		}
	}
}
.ant-layout-footer {
	font-size: 10px;
	height: $height20;
	padding: 5px;
}
.ant-layout-sider-collapsed {
	.ant-layout-sider-children {
		.profile-space {
			height: 43px;
			padding-left: 17%;
		}
		.logo {
			width: auto;
			span {
				border-radius: 0px;
				margin-left: 2%;
				width: 95%;
			}
		}
	}
}
#myGrid {
	margin: 10px;
}
.custom-row-style {
	background-color: #dfe6e9 !important;
}
.ant-form-item-label {
	line-height: 25px;
}
.ant-form-item {
	margin-bottom: 8px;
}
.user-details-edit {
	.ant-input-group-addon {
		background-color: $white;
	}
	.pStyle {
		color: rgba(0, 0, 0, 0.85);
		display: inline-block;
		font-size: 16;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 0;
	}
}
.ant-modal {
	padding: 0;
}
.addUser {
	.ant-modal-body {
		overflow-y: auto;
	}
}
.downloadData {
	.ant-modal-body {
		padding: 24px 0 0;
	}
	.ant-form-item {
		padding: 0 24px;
	}
	.ant-modal-footer {
		border-radius: unset;
		border-top: unset;
		padding: 0;
		text-align: unset;
	}
	.duplicate-footer {
		background: transparent;
		border-radius: 0 0 2px 2px;
		border-top: 1px solid #f0f0f0;
		margin-bottom: 0;
		margin-top: 32px;
		padding: 10px 16px;
		text-align: right;
	}
}
.circled-progress {
	padding-bottom: 24px;
	text-align: center;
	transition: all 0.5s;
	p {
		margin-top: 10px;
		transition: all 0.5s;
	}
}

.ant-dropdown-menu-item {
	display: inline-block;
	padding: 12px;
	text-align: center;
	margin: 2px auto;
}

.bdr-right {
	border-right: 1px !important;
}
.ant-dropdown-menu {
	padding: 15px;
}
.ant-dropdown {
	width: 270px;
}
.ant-menu-horizontal>.ant-menu-item>a {
	color: $white;
	&:hover {
		color: $white;
	}
}
.tabs-content-layout {
	.ant-tabs-content {
		background-color: $white;
		border-left: 1px solid $tab-content-border;
		border-right: 1px solid $tab-content-border;
		box-sizing: border-box;
		min-height: $height20;
	}
	.ant-tabs-nav-list {
		height: 25px;
		height: $height43;
	}
}
.loading {
	position: fixed;
	left: 0px;
	top: 50%;
	width: 100%;
	height: 100%;
	z-index: 9999;
}
.center-align-loader {
	align-items: center;
	display: flex;
	height: $height-full;
	justify-content: center;
}
.carousel-img-container {
	background: rgba(0, 0, 0, 0.5);
	opacity: 0.5;
	z-index: 1000;
	p {
		color: $carousel-color;
	}
	h2 {
		color: $carousel-color;
	}
	.ant-card-body {
		font-size: 13px;
	}
}
.mfa-settings-btn {
	button {
		margin-right: 8px;
	}
}
.header-menu-option {
	.ant-menu-item {
		margin: 0px 6px !important;
		padding: 3px !important;
		.anticon {
			margin-right: unset;
		}
	}
	.ant-dropdown {
		left: 1031px;
		top: 41px;
	}
	.ant-menu-item-selected {
		border-bottom: none !important;
	}
}

#multi-module {
	margin-right: 5px !important;
	&>a {
		color: $white;
	}
}
.multi-module-avatar {
	vertical-align: middle;
}
.userStatusBtn {
	clear: both;
	display: inline-block;
	margin-left: 10px;
	vertical-align: top;
}
.ant-tabs>.ant-tabs-nav {
	margin: 0;
}
.ant-tabs>.ant-tabs-content-holder {
  background-color: $white;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav {
	.ant-tabs-tab {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border: none;
		height: $height20;
	}
}

.profile {
	.ant-tabs-nav {
		margin: 5px;
		.ant-tabs-tab { 
			border: 1px solid #f0f0f0 !important;
			border-bottom-color: #FFFFFF !important;
			height: 25px !important;
		}
	}

	.ant-modal-body {
		height: 390px;
		overflow-y: auto;
		padding-top: 5px;
	}
}

.ant-tabs-tab {
	font-weight: 500;
}
.login {
	.ant-tabs-nav-list {
		button {
			font-size: 12px !important;
		}
	}
	.ant-layout-content {
		padding: 0px !important;
	}
}
.thumb-vertical {
	background-color: rgba(123, 154, 255, 0.418);
	border-radius: inherit;
	cursor: pointer;
	overflow-y: auto !important;
}
.app-tabpane {
	height: calc(100vh - 80px);
	overflow: hidden;
}
.Order-Reports {
	height: calc(130vh - 80px);
}
.Report-History {
	height: calc(110vh - 80px);
}
.report-history-container {
	.report-history-options {
		margin-top: 2%;
		margin-left: 1%;
	}
}
.display-none {
	display: none;
}
.pad-top-10{
	padding-top: 10px;
}
.pad-right-10{
	padding-right: 10px;
}
.bold{
	font-weight: bold;
}
.dashboard-tab {
	.ant-tabs-nav {
		padding: 0px 5px;
	}
	.ant-tabs-content-holder {
		display: none;
	}
}
.ag-theme-balham-dvr-header {
	--ag-header-foreground-color: #f5f5f5;
    --ag-header-background-color: #EF373E;
}
.ag-theme-balham {
	background-color: #f5f5f5;
	height: auto;
	width: $width-full;
	.ag-row-hover {
		font-weight: bold !important;
		background: inherit;
	}
}
.helper {
	--reactour-accent: #5cb7b7;
	line-height: 1.3;
	color: #2d2323;
	padding-right: 46px;
}
.mask {
	opacity: 0.5;
	background-color:#fffa65;
	pointer-events: auto;
    cursor: pointer;
}
.left-walkthrough {
	float: left;
}
.skip-walkthrough {
	color: #fff;
    border: #EF373E;
    background: #EF373E;
    padding: -0.7em .7em;
    display: block;
    cursor: pointer;
    margin: 1em auto;
}
.skip-walkthrough-go {
	color: #fff;
    border:#d2dae2;
    background: #d2dae2;
    padding: -0.7em .7em;
    display: block;
    cursor: pointer;
    margin: 1em auto;
}
.reportHistorySelect{
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	color: #465660;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
/*Projection Styles*/
.proj_outer { border-radius:3px; border:1px solid #d6d6d6; background:#FFF; height:25px; width:100%; max-width:120px; position:relative;}
.proj_outer .weeks { float:left; width:100%;height: 26px;}
.proj_outer .block_color { float:left; height:25px; width:1.923%;}
.proj_outer .red { background:#ff0000;}
.proj_outer .red_false { background:#ff0000; margin-top:5px; height:20px; }
.proj_outer .yellow { background:#ffff00;}
.proj_outer .yellow_false { background:#ffff00; margin-top:5px; height:20px; }
.proj_outer .greenchart { background:#1a8c37;}
.proj_outer .greenchart_false { background:#1a8c37; margin-top:5px; height:20px; }
.span-bold {
	font-weight: bold;
}
.span-value {padding-left: 2px;padding-right: 10px;}

.cds-access-details {
	.ant-descriptions-item-content {
		padding: 0px !important;
	}
}

.footer-font-size {
	font-size: 10px;
}

.multi-mod-menu {
	margin-top: 10px;
	.ant-dropdown-menu {
		border-radius: 2px;
	}
}
.reports-list-header {
	padding: 2%;
    background-color: #dfe4ea;
    color: #465660;
    margin-bottom: 4%;
    font-weight: 600;
	cursor: pointer;
}

.pointer-cursor {
	cursor: pointer;
	padding: 2px;
}
.simulator-menu {
	.ant-divider-horizontal {
		margin: 10px 0px !important;
	}
	.ant-dropdown-menu {
		border-radius: 4px !important;
	}
	.ant-dropdown-menu-vertical {
		margin-top: 13px !important;
	}
	.switch-user {
		background-color: #FFFFFF;
		border-radius: 3px;
		padding: 10px 0px;
		p {
			margin: 2px 0px !important;
			color: #636e72;
			font-weight: 500;
		}
	}
}

#email {
	text-transform: lowercase;
}