$grid-head-bg-color: #eff1f4;

.ag-header {
    background-color: $grid-head-bg-color;
    background-color: var(--ag-header-background-color, $grid-head-bg-color);
    border-bottom-color: $grid-head-bg-color;
    border-bottom-color: var(--ag-border-color, $grid-head-bg-color);
}

.inv-ui {
    .row-has-error {
        border: 1px solid #d63031;
        padding: 1px !important;
        line-height: 20px;
        width: 20px;
    }

    .status-error {
        border: 1px solid #d63031;
        line-height: 24px;
        height: 25px;
        .status-value {
            margin-left: 5px;
        }
    }
}
