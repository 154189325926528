$search-btn-bg: #a31328;
$search-btn-border: #d86173;

.pc-search {
    margin: 2px 0px;
    .qs-btn {
        background-color: $search-btn-bg;
        border-color: $search-btn-border;
    }
    .ss-btn {
        background-color: $search-btn-bg;
        border-color: $search-btn-border;
    }
}

.search-form {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.pc-qs-dropdown {
    width: 400px !important;
    .ant-dropdown-arrow {
        border-top-color: #EF373E !important;
        border-left-color: #EF373E !important;
    }
}

.search-form .head {
    background-color: #EF373E;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 0px 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    line-height: 24px;
    .title {
        color: #FFFFFF;
        margin: 4px !important;
    }
}

.search-form .body {
    padding: 10px;
    border: 1px solid #f2f2f2;
    z-index: 1000;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.qs-action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 3px;
}

.search-criteria-tooltip {
    .ant-list-header {
        text-align: center;
        font-weight: bold;
        font-size: 13px;
        padding-top: 0px !important;
        padding-bottom: 5px !important;
    }
}

.search-criteria {
    width: calc(100vw - 315px);
    .ant-tag {
        background-color: #2f3640 !important;
        div {
            :nth-child(1) {
                color: #e1b12c !important;
            }
        }
    }
}