.om-site-executor {
    .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px;
        .anticon-sync {
            font-size: 18px;
        }
    }

    .content {
        padding: 10px;
        .ant-card:nth-child(1) {
            margin-bottom: 5px;
        }
    }
}

.site-exec-popup-list {
    .ant-card:nth-child(1) {
        margin-bottom: 5px;
    }
}

.site-exec-popup-container {
    p {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;
        color: #0099dd;
    }
}

.content-more {
    .ant-popover-content {
        .ant-popover-inner-content {
            height: 290px;
            overflow: auto;
        }
    }
}
.header-filter {
    overflow: hidden;
    width: calc(80%);
    text-overflow: ellipsis;
    white-space: nowrap;

}
.fc-header-details-body {
        height: 350px;
        overflow: auto;
}
.parts-firm-modal {
    width: calc(60%) !important;
}