.rtf-firm-release-ui {
    .row-has-error {
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: #ff6b6b !important;
                border-color: #ff6b6b !important;
            }
        }
    }

    .status-error {
        border: 2px solid #d63031;
        line-height: 24px;
        height: 25px;

        .status-value {
            margin-left: 5px;
        }
    }

    .nbs-grid-container {
        .ag-cell-value {
            padding: 2px 5px !important;
        }
        
        .ant-input {
            border-radius: 8px;
            font-size: 12px !important;
        }
        
        .ant-select-selector {
            border-radius: 8px !important;
            font-size: 12px !important;
        }
        
        .ant-picker {
            border-radius: 8px !important;
            font-size: 12px !important;
            height: 24px;
            .ant-picker-input > input{
                font-size: 12px !important;
            }
        }
    }


    .ag-react-container {
        .ant-select {
            width: 100% !important;
            padding: 0 !important;
            height: 26px !important;
            border-radius: 8px !important;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 24px;
            padding: 0 11px;
        }

        .ag-cell {
            border-right: 1px solid #d9dcde !important;
        }
    }
}

.ag-tool-panel-wrapper {
    min-width: 300px;
    max-width: 310px;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    height: auto;
    white-space: nowrap;
    color: #ff7675;
    font-size: 12px;
    font-family: "Avenir", sans-serif;
    font-variant: tabular-nums;
}

.grey-input {
    background-color: #eee;
}

.rtf-firm-modal {
    width: 700px;

    .ant-modal-body {
        height: 350px;
        overflow-y: auto;
        padding-top: 5px;
    }
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
    margin-left: 0px;
}

.ant-modal-confirm-error>.ant-modal-content {
    width: 500px;
}

.ag-cell.rtf-firm-editable {
    padding: 1px 4px;
}

.ag-sort-order{
   display: none;
}

.mandatory {
    border: #CCCCCC solid 2px;
}