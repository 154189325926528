@import "./cds/dashboard.scss";

@mixin pc-dashboard-help-style {
    border-radius: 3px;
    padding: 3px 5px 3px 5px;
}

$border-bottom: 1px solid #CCCCCC;
$padding5: 5px;

.ssp-dashboard {
    .ant-card {
        &:hover {
            transform: none;
        }
        &-body {
            padding: 10px !important;
        }
    }
    .ag {
        &-theme-balham {
            font-size: 13px !important;
        }
        &-header {
            border-color: rgba(250, 250, 250, 1) !important;
            background-color: rgba(250, 250, 250, 1) !important;
        }
        &-root-wrapper {
            border: rgba(250, 250, 250, 1) !important;
        }
        &-center-cols-container {
            width: 100% !important;
        }
        &-header-cell {
            background-color: rgba(250, 250, 250, 1);
            &:last-child {
                &::after {
                    border: none !important;
                    content: none;
                }
            }
        }
        &-row {
            background-color: #FFFFFF;
            border-color: rgba(240, 240, 240, 1);
            color: #333333 !important;
            &-hover {
                background-color: rgba(33, 150, 243, 0.1) !important;
                .ag-cell-value {
                    color: #333333 !important;
                }
            }
        }
        &-grid-container {
            .ant-picker-input {
                input {
                    &:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
    a {
        color: #0099FF !important;
    }

    .overall-status {
        background-color: #dfe6e9;
        color: #222f3e;
    }

    .ssp-timeline-container {
        counter-reset: step;
        padding: 5% 2% 5% 3%;
        border: 1px solid #d9d9d9;
        background-color: #d9d9d9;
        .ssp-timeline {
            background-color: white;
            padding: 13px;
            z-index: 999999;
            .progressbar {
                margin-top: -1%;
                li {
                    list-style-type: none;
                    width: 7.6%;
                    float: left;
                    font-size: 9px;
                    position: relative;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #1e272e;
                    white-space: normal;
                    word-wrap: break-word;
                    &::before {
                        width: 10px;
                        height: 10px;
                        content: '';
                        counter-increment: step;
                        line-height: 20px;
                        display: block;
                        text-align: center;
                        margin: 7px auto 10px auto;
                        border-radius: 50%;
                        z-index: 2;
                        border: 3px solid #55b776;
                    }
                    &::after {
                        width: 76%;
                        height: 2px;
                        content: '';
                        position: absolute;
                        background-color: #55b776;
                        left: -50%;
                        margin-left: 15%;
                        margin-top: -17px;
                        z-index: 0;
                    }
                    &:first-child::after {
                        content: none;
                    }
                    &.initial {
                        padding-top: 13px;
                        width: 6%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.last {
                            margin-right: 10px;
                        }
                    }
                    &.active {
                        font-weight: bold;
                        color: #165616;
                        &::before {
                            -moz-transition:all 0.5s ease-in-out;
                            -webkit-transition:all 0.5s ease-in-out;
                            -o-transition:all 0.5s ease-in-out;
                            -ms-transition:all 0.5s ease-in-out;
                            transition:all 0.5s ease-in-out;
                            -webkit-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -moz-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -ms-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            -o-animation: 1.5s timelineBlink normal infinite ease-in-out;
                            animation: 1.5s timelineBlink normal infinite ease-in-out;
                            border: 3px solid #55b776;
                            height: 12px;
                            margin: 2px auto 10px auto;
                            width: 12px;
                        }
                        &~::after {
                            background-color: #55b776;
                        }
                        &.red-class {
                            &::before {
                                content: '';
                                background-color:#ffbd05 !important;
                            }
                        }
                        &.green-class {
                            &::before {
                                content: '';
                                background-color:#fff !important;
                            }
                        }
                    }
                    &.done {
                        color: #1e272e;
                        &+::after {
                            background-color: #55b776;
                        }
                        &::before {
                            width: 10px;
                            height: 10px;
                            content: '';
                            counter-increment: step;
                            line-height: 20px;
                            display: block;
                            text-align: center;
                            margin: 3px auto 10px auto;
                            border-radius: 50%;
                            border: 3px solid #55b776;
                            background-color:#55b776;
                        }
                    }
                    &.portal {
                        background: linear-gradient(180deg, #d2dae2 35%, #ffffff 37%, #ffffff 61%, #d2dae2 30%);
                        padding-top: 13px;
                        width: 6%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                            margin-right:10px;
                        }
                    }
                    &.uat {
                        background: linear-gradient(180deg,#d2dae2 35%, #ffffff 37%, #ffffff 61%,#d2dae2 30%);
                        padding-top: 13px;
                        width: 8%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                            margin-right:10px;
                        }
                    }
                    &.production {
                        background: linear-gradient(180deg,#d2dae2 35%, #ffffff 37%, #ffffff 61%,#d2dae2 30%);
                        padding-top: 13px;
                        width: 7%;
                        height: 120px;
                        margin-top: -47px;
                        &::after {
                            top: 74px;
                        }
                        &::before {
                            margin: 37px auto 15px auto !important;
                        }
                        &.first {
                            border-top-left-radius: 18px;
                            border-bottom-left-radius: 18px;
                        }
                        &.last {
                            border-top-right-radius: 18px;
                            border-bottom-right-radius: 18px;
                        }
                    }
                    .portal-title-first {
                        margin-top: -70px;
                        margin-left: 110%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .portal-status-first {
                        margin-top: 15px;
                    }
                    .uat-title-first {
                        margin-top: -70px;
                        margin-left: 230%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .uat-status-first {
                        margin-top: 15px;
                    }
                    .production-title-first {
                        margin-top: -70px;
                        margin-left: 30%;
                        font-size: 13px;
                        font-weight: 700;
                        position: absolute;
                        z-index: 5;
                    }
                    .production-status-first {
                        margin-top: 15px;
                    }
                    &.cancelled-status {
                        color: #e74c3c;
                        padding-top: 16px;
                        &::before {
                            width: 10px;
                            height: 10px;
                            content: 'X';
                            counter-increment: step;
                            line-height: 10px;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            background-color: transparent;
                            z-index: 2;
                            font-size: 12px;
                            font-weight: bold;
                            color: #e74c3c;
                            border: none;
                            padding-left: 2px;
                        }
                    }
                }
            }
        }
    }
  
    .ag-menu {
        overflow: unset !important;
    }

    .wrap-text {
        line-height: 20px;
        padding: 5px 0px;
        word-break: break-word;
        text-align: center;
    }

    .timeline-toggle-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .timeline-toggle-content {
            word-break: break-word;
            text-align: center;
            margin-left: 3px;
            cursor: pointer;
        }
    }
}
.txn-step {
    display: flex;
    flex-direction: row;
    padding: 15px 5px 2px 5px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 14px !important;
    div {
        flex: 0 1 100px;
    }

    &:nth-child(even) {
        background: #fafafa;
    }

    .ant-steps-item-finish {
        .ant-steps-icon-dot {
            background: rgb(58, 227, 116) !important;
        }
        .ant-steps-item-tail {
            &::after {
                background: rgb(58, 227, 116) !important;
            }
        }
    }
    .ant-steps-item-process {
        .ant-steps-icon-dot {
            -moz-transition:all 0.5s ease-in-out;
            -webkit-transition:all 0.5s ease-in-out;
            -o-transition:all 0.5s ease-in-out;
            -ms-transition:all 0.5s ease-in-out;
            transition:all 0.5s ease-in-out;
            -webkit-animation: 1.5s blink normal infinite ease-in-out;
            -moz-animation: 1.5s blink normal infinite ease-in-out;
            -ms-animation: 1.5s blink normal infinite ease-in-out;
            -o-animation: 1.5s blink normal infinite ease-in-out;
            animation: 1.5s blink normal infinite ease-in-out;
        }
    }
    .ant-steps-item-content {
        width: 115px !important;
        margin-top: 2px !important;
    }
    .ant-steps-item-title {
        font-size: 12px !important;
        padding-left: 25px !important;
    }
}

.txn-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    & > div {
        flex: 0 1 100px;
    }
    &>.txn-header-right {
        display: flex;
        &>div {
            width: 100px;
            font-weight: 600;
        }
    }
}

@keyframes blink {
    0% { background-color: #ff9f43; }
    50% { background-color: #f9ca24; }
    100% { background-color: #f6e58d; }
}

@keyframes timelineBlink {
    0% { background-color: #f9ca24; }
    50% { background-color: #ffffff; }
    100% { background-color: #f9ca24; }
}

.pc-dashboard {
    height: calc(100vh - 100px);
    padding: 10px !important;
    .ant-card-body {
        padding: 0px;
    }
    .pc-dashboard-header {
        display: flex;
        justify-content: flex-end;
    }
    &.ant-card {
        &:hover {
            transform: none;
        }
    }
    @mixin progress-bar-full {
        flex: 0 0 100% !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    @mixin height17 {
        height: 17px;
    }
    .pc-dashboard-table {
        thead tr {
            height: 36px !important;
        }
        thead th {
            padding: 8px 8px !important;
            text-align: center !important;
            color: #666666 !important;
        }
        .tile-container {
            width: 130px;
            height: 70px;
            border-radius: 5px;
            color: #949494;
            box-shadow: 0 3px 5px 0 rgb(36 50 66 / 10%);
            margin: auto;
        }
        .tile-head {
            text-align: center;
            font-size: 16px;
            color: #949494;
            margin-top: 5px;
        }

        .plant-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .plant-details {
                margin-right: 5px;
            }
            .fc-refresh-rqrd {
                flex: 0 0 40px;
                text-align: center;
            }
        }

        .progress-bar {
            display: flex;
            justify-content: stretch;
            font-size: 12px;
            align-items: center;
            padding: 5px;
            text-align: center;
            margin-top: 4px;
            &.agility {
                .full-width {
                   @include progress-bar-full;
                }
                .completed {
                    background: #1B9CFC;
                    padding: 0px 2px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border: 1px solid #1B9CFC;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 10px;
                    flex: 0 0 50%;
                    @include height17;
                }
                .pending {
                    padding: 0px 2px;
                    background-color: #C8D6E5;
                    border: 1px solid #C8D6E5;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    font-size: 10px;
                    flex: 0 0 50%;
                    @include height17;
                }
            }
            &.om {
                .full-width {
                    @include progress-bar-full;
                }
                .completed {
                    background: #2ECC71;
                    padding: 0px 2px;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border: 1px solid #2ECC71;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 10px;
                    flex: 0 0 33.33%;
                    @include height17;
                }
                .responded {
                    background: #FECA57;
                    padding: 0px 2px;
                    border: 1px solid #FECA57;
                    color: #444444;
                    font-weight: 600;
                    font-size: 10px;
                    flex: 0 0 33.33%;
                    @include height17;
                }
                .pending {
                    padding: 0px 2px;
                    background-color: #FF4D4D;
                    border: 1px solid #FF4D4D;
                    color: #FFFFFF;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    font-size: 10px;
                    font-weight: 600;
                    flex: 0 0 33.33%;
                    @include height17;
                }
            }
        }
    }
    .refresh-icon {
        font-size: 18px;
        color: #636e72;
        padding: 4px 10px 0px 5px;
    }
    thead {
        .ant-table-cell {
            background-color: #FDFDFD !important;
        }
    }
    tbody {
        background-image: linear-gradient(to bottom, #f5f6fa, #f7f7fa, #f8f8fa, #fafafb, #fbfbfb);
    }

    .code {
        font-size: 20px;
        text-align: left;
    }

    .name {
        font-size: 12px;
        text-align: left;
    }
}

.om-dashboard-helptext {
    font-size: 11px;
    & > div {
        font-weight: 300;
        padding: $padding5;
        &:not(:last-child) {
            border-bottom: $border-bottom;
        }
    }
    .large-num {
        @include pc-dashboard-help-style;
        background-color: #949494;
        color: #ecf0f1;
    }
    .green-num {
        @include pc-dashboard-help-style;
        background-color: #2ECC71;
        color: #ecf0f1;
    }
    .yellow-num {
        @include pc-dashboard-help-style;
        background-color: #FECA57;
        color: #444444;
    }
    .red-num {
        @include pc-dashboard-help-style;
        background-color: #FF4D4D;
        color: #ecf0f1;
    }
}

.pc {
    background-color: #dfe6e9;
    .dashboard-content:not(.no-access) {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(50deg, #ff7675, #fc8888, #eacf9f, #7ebfd6, #5eb6d6);
            clip-path: polygon(0% 70%,100% 35%,100% 100%,0% 100%);
            z-index: 0;
        }

        div:not(.search-criteria) {
            .pc-dashboard {
                margin-top: 10px !important;
            }
        }
    }

    .ant-table-body {
        min-height: calc(100vh - 230px) !important;
        max-height: calc(100vh - 220px) !important;
    }

    .ant-table-placeholder {
        border: none;
        .ant-table-cell {
            border: none !important;
        }
    }

    .pc-dashboard-table {
        margin-left: 5px;
        margin-right: 5px;
    }

    .pc-dashboard-header {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.change-view-overlay {
    .ant-dropdown-menu {
        padding: 5px 5px 10px !important;
    }
    .ant-list-item {
        padding: 5px 0px !important;
    }
    .ant-dropdown-menu-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}