.helpdesk-card {
    height: 100%;
    &-right-divider {
        margin: 5px 0px !important;
    }
}

.helpdesk-header {
    margin: 18px 0px;
    color: #f2f2f2;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.helpdesk-logo {
    margin-top: 2px; 
    margin-bottom: 2px;
}