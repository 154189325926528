@mixin header-view {
    height: 30px !important;
    line-height: 30px !important;
}

@mixin header {
    padding: 0 8px !important;
    button {
        font-size: 13px !important;
        line-height: 30px !important;
    }
}

@mixin year-picker-content {
    height: 170px !important;
    font-size: 12px !important;
}

@mixin year-month-panel-content {
    height: 200px !important;
    .ant-picker-header {
        @include header;
    }
    .ant-picker-header-view {
        @include header-view;
    }
    .ant-picker-content {
        @include year-picker-content;
    }
}

.ant-picker-panel-layout {
    .ant-picker-date-panel {
        height: 200px !important;
        .ant-picker-header {
            @include header;
        }
        .ant-picker-header-view {
            @include header-view;
        }
        .ant-picker-body {
            padding: 0px 5px !important;
            font-size: 10px !important;
            .ant-picker-content {
                border-collapse: initial !important;
                .ant-picker-cell {
                    padding: 1px !important;
                    &::before {
                        height: 18px !important;
                    }
                    .ant-picker-cell-inner {
                        height: 13px !important;
                        line-height: 13px !important;
                    }
                }
            }
        }
    }
    .ant-picker-footer {
        height: 25px !important;
        font-size: 12px !important;
        .ant-picker-ranges {
            height: 25px !important;
        }
    }
    .ant-picker-month-panel {
        @include year-month-panel-content;
    }
    .ant-picker-year-panel {
        @include year-month-panel-content;
    }
}