.ant-layout-sider-collapsed {
	.ant-layout-sider-children {
		.ant-menu-inline-collapsed {
			.ant-menu-item {
				padding: 0 27px;
			}
		}
		.ant-menu-inline-collapsed>.ant-menu-item {
			.anticon {
				font-size: 25px;
			}
		}
		.profile-space {
			.gx-avatar-name {
				display: none;
			}
		}
	}
}
.ant-layout-sider {
	.ant-menu-item {
		font-size: 14px !important;
		.anticon {
			font-size: 20px;
		}
	}
}

.pc-sider {
	:not(.ant-layout-sider-collapsed) {
		&.ant-layout-sider {
			flex: 0 0 245px !important;
			max-width: 245px !important;
		}
	}
}

.ant-menu-submenu-title {
	.anticon {
		font-size: 20px;
	}
}

.text-icon {
	font-size: 14px !important;
	font-weight: 600;
	color: #465660 !important;
	vertical-align: inherit;
}